import React from 'react';
import Case from '../components/case'

const CaseDanone = () =>
    <Case 
        seoTitle="Forle Marketing Case Danone"
        images={["1.jpg", "2.jpg", "3.jpg", "4.jpg"]} 
    >  
          <h1>Case Danone</h1>
          <div className="row justify-content-center my-5">
              <div className="col-sm-12 col-lg-6">
                  <p>Para divulgar o lan&ccedil;amento do <strong>Danoninho Para Levar,</strong> a Forle desenvolveu um <strong>pula-pula </strong>onde na compra de uma bandeja de Danoninho, as crian&ccedil;as podiam ficar <strong>5 minutos se divertindo</strong>. O pula-pula chamou bastante a aten&ccedil;&atilde;o dos pais e ainda mais das crian&ccedil;as. </p>
              </div>
              <div className="col-sm-12 col-lg-6">
                <p>Com uma <strong>estrutura simples e muito espa&ccedil;o dentro das lojas</strong>, o pula-pula trouxe ainda mais <strong>visibilidade</strong> para o produto. Trazendo <strong>recorde de vendas nas lojas</strong>, o projeto despertou a curiosidade e ganhou muita repercuss&atilde;o. A Forle participou ativamente, <strong>desde a execu&ccedil;&atilde;o at&eacute; a composi&ccedil;&atilde;o das estrat&eacute;gias.</strong></p>
              </div>
          </div>
          
    </Case>

export default CaseDanone